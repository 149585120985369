window.page = false
const dropdowns = document.querySelectorAll('.dropdown');

dropdowns.forEach(dropdown => {
  	dropdown.addEventListener('click', (e) => {
  	 	dropdown.classList.toggle('dropdown__options--visible')
  	})
  	
  	dropdown.querySelectorAll('.dropdown__options .dropdown__option span').forEach(opt => {
  	  	opt.addEventListener('click', (e) => {
  	  		dropdown.querySelectorAll('.dropdown__options .dropdown__option').forEach(sel => {
  	  			sel.classList.remove('dropdown__option--selected')
  	  		})
  	  		opt.parentElement.classList.add('dropdown__option--selected')
  	  	  	dropdown.querySelector('.dropdown__selected span').innerHTML = opt.getAttribute('data-lang');
  	  	})
  	})
})
function scrollCount() {
	let scroll = $(document).scrollTop()
	if(scroll > $('.menu-block').outerHeight() / 2)
		$('.menu-block').addClass('fixed')
	else
		$('.menu-block').removeClass('fixed')

	if(scroll > $('#section_9').offset().top + $('#section_9').outerHeight() - 100) {
		$("#main").addClass("unpadding")
		$("#section_10").addClass("unfixed")
	} else {
		$("#main").removeClass("unpadding")
		$("#section_10").removeClass("unfixed")
	}
	$('section').each(function(){
		let sectionTop = $(this).offset().top - 100
		if(sectionTop < scroll 
			&& sectionTop + $(this).outerHeight() > scroll 
			&& $(this).attr('id') != 'section_10'
			&& !$('.fixed-scroll [data-fix="'+$(this).attr('id')+'"] button').hasClass('focus')
			|| 
			sectionTop < scroll 
			&& sectionTop + $(this).outerHeight() > scroll 
			&& $(this).attr('id') != 'section_10'
			&& !window.page
		) {
			$('.fixed-scroll button').removeClass('focus')
			$('.fixed-scroll [data-fix="'+$(this).attr('id')+'"] button').addClass('focus')
			$('.fixed-scroll [data-fix="'+$(this).attr('id')+'"] button').change()
		}
		if($(document).height() - window.outerHeight < scroll && !$('.fixed-scroll [data-fix="section_10"] button').hasClass('focus')) {
			$('.fixed-scroll button').removeClass('focus')
			$('.fixed-scroll [data-fix="section_10"] button').addClass('focus')
			$('.fixed-scroll [data-fix="section_10"] button').change()
		}
	})
}
gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(MorphSVGPlugin)
gsap.fromTo('#showing', {
	y: $(window).height(),
	background: "#edece8",
	height: $(window).height(),
}, {
	duration: 1,
	y: 0,
	background: "#edece8",
})
gsap.to('#showing', {
	delay: 1,
	height: 0,
	duration: 1.5
})
gsap.to("#main", {
	delay: 1,
	duration: 1,
	opacity: 1,
})
gsap.from('.menu-block', {
	y: -200,
	duration: 1,
	delay: 1.5
})
function gsapFunction(selector, duration, delay, start_1 = "top 95%", end_1 = "+= 100px") {
	gsap.fromTo(selector, {
		y: $(window).width() > 991 ? 50 : 20
	}, {
		duration: duration,
		delay: delay, 
		y: $(window).width() > 991 ? -30 : 15,
		scrollTrigger: {
			trigger: selector,
			start: start_1,
			//end: end_1,
			//scrub: 1,
			//markers: true,
			toggleActions: "restart none none none"
		}
	})
}
$('#section_1 .container').each(function(){
	gsap.fromTo($(this), {
		y: $(window).width() > 991 ? 100 : 50
	}, {
		duration: 1,
		y: 0,
		scrollTrigger: {
			trigger: "#section_1 .slider-first",
			//scrub: 1,
			toggleActions: "restart none none none"
		}
	})
})


//section_2
gsapFunction("#section_2 .action_1", 2, 1)
gsapFunction("#section_2 .action_2", 2, 1)
gsapFunction("#section_2 .action_3", 2, 1)

//section_3
gsapFunction("#section_3 .action_1", 2, 1)
gsapFunction("#section_3 .action_2", 2, 1)
gsapFunction("#section_3 .action_3", 2, 1)
gsapFunction("#section_3 .action_4", 2, 1)
gsapFunction("#section_3 .action_5", 2, 1)

//section 4
gsapFunction("#section_4 .slider", 2, 1)

//section 5
gsapFunction("#section_5 .action_1", 2, 1)
gsapFunction("#section_5 .action_2", 2, 1)

//section 6
gsapFunction("#section_6 .action_1", 2, 1)
gsapFunction("#section_6 .action_2", 2, 1)
gsapFunction("#section_6 .action_3", 2, 1)
gsapFunction("#section_6 .action_4", 2, 1)
gsapFunction("#section_6 .action_5", 2, 1)
gsapFunction("#section_6 .action_6", 2, 1)
gsapFunction("#section_6 .action_7", 2, 1)

//section_7
gsapFunction("#section_7 .action_1", 2, 1)
gsapFunction("#section_7 .action_2", 2, 1)
gsapFunction("#section_7 .action_3", 2, 1)

//section_8
gsapFunction("#section_8 .action_1", 2, 1)
gsapFunction("#section_8 .action_2", 2, 1)
gsapFunction("#section_8 .action_3", 2, 1)

//section_9
gsapFunction("#section_9 .action_0", 2, 1)
gsapFunction("#section_9 .action_1", 2, 1)
gsapFunction("#section_9 .action_2", 2, 1)
gsapFunction("#section_9 .action_3", 2, 1)
gsapFunction("#section_9 .action_4", 2, 1)
gsapFunction("#section_9 .action_5", 2, 1)
gsapFunction("#section_9 .action_6", 2, 1)

$(document).ready(function(){
	setTimeout(function(){
		document.querySelectorAll('.pagination').forEach(pagination => {
			let svg = pagination.querySelector('svg'),
			    path = svg.querySelector('path');
			
			pagination.querySelectorAll('li').forEach(entry => {
			
			    let button = entry.querySelector('button');
			    $(button).on('change', e => {
			        let old = pagination.querySelector('li.slick-active');
			
			            // old.classList.remove('slick-active');
			            // entry.classList.add('slick-active');
			
			            gsap.to(svg, {
			                x: entry.offsetLeft,
			                duration: .3,
			                ease: 'power1.out'
			            });
			
			            let right = getElementIndex(old) < getElementIndex(entry);
			
			            gsap.to(path, {
			                keyframes: [{
			                    morphSVG: right ? 'M6,8 C8.209139,8 12,6.209139 12,4 C12,1.790861 8.209139,0 6,0 C3.790861,0 2,1.790861 2,4 C2,6.209139 3.790861,8 6,8 Z' : 'M6,8 C3.790861,8 0,6.209139 0,4 C0,1.790861 3.790861,0 6,0 C8.209139,0 10,1.790861 10,4 C10,6.209139 8.209139,8 6,8 Z',
			                    duration: .2,
			                    ease: 'power4.out'
			                }, {
			                    morphSVG: right ? 'M6.00022611,8 C8.20936511,8 10.0002261,6.209139 10.0002261,4 C10.0002261,1.790861 8.20936511,0 6.00022611,0 C3.79108711,0 5.00022611,1.790861 5.00022611,4 C5.00022611,6.209139 3.79108711,8 6.00022611,8 Z' : 'M6,8 C3.790861,8 2,6.209139 2,4 C2,1.790861 3.790861,0 6,0 C8.209139,0 7,1.790861 7,4 C7,6.209139 8.209139,8 6,8 Z',
			                    duration: .15,
			                    ease: 'power3.out'
			                }, {
			                    morphSVG: 'M6,8 C8.209139,8 10,6.209139 10,4 C10,1.790861 8.209139,0 6,0 C3.790861,0 2,1.790861 2,4 C2,6.209139 3.790861,8 6,8 Z',
			                    duration: .4,
			                    ease: 'elastic.out(1, .65)'
			                }]
			            });
			    });
			})
		});
		
		function getElementIndex(node) {
		    let index = 0;
		    while((node = node.previousElementSibling)) {
		        index++;
		    }
		    return index;
		}
		scrollCount()
		window.page = true
	}, 500)
	$('a[href*=#]').on('click', function(e) {
  	  	e.preventDefault();
  	  	$('html, body').animate({ scrollTop: $($(this).attr('href')).offset().top - 73}, 1000, 'linear');
  	});

  	$(document).on('focus', 'input[type="text"], textarea', function(){
  		$(this).parent().addClass('focus')
  	})
  	$(document).on('blur', 'input[type="text"], textarea', function(){
  		$(this).parent().removeClass('focus')
  	})

  	$(document).on('click', '.slider .more', function(e){
  		e.preventDefault()
  		$(this).parent().find('.txt').slideToggle()
  	})

  	$(document).on('click', '#section_5 .more, #section_6 .more', function(e){
  		e.preventDefault()
  		$(this).parent().find('.txt').toggleClass("show")
  	})

  	$(document).on('click', '.mobile-b', function(){
  		let menu = $('.menu-block .menu'),
  			$this = $(this)
  		if(parseInt(menu.css('left')) != 0) {
  			menu.css({
  				paddingTop: $('.menu-block').outerHeight(),
  				height: window.outerHeight,
  				left: 0,
  				boxShadow: "10px 0 20px rgba(0, 0, 0, .2)"
  			})
  			$this.addClass('active')
  			var firstClick = true
  			$(document).bind('click.menu', function(e){
  				if(!firstClick) {
  					menu.css({
  						paddingTop: '',
  						height: '',
  						left: '',
  						boxShadow: ''
  					})
  					$this.removeClass('active')
  					$(document).unbind('click.menu')
  				}
  			})
  			firstClick = false
  		} else {
  			menu.css({
  				paddingTop: '',
  				height: '',
  				left: '',
  				boxShadow: ''
  			})
  			$this.removeClass('active')
  		}
  	})

  	$('input[type="text"], textarea').each(function(){
  		let $this = $(this)
  		if($this.val().length >= 1) 
  			$(this).parent().addClass('typed')
  		else
  			$(this).parent().removeClass('typed')
  	})

  	$(document).on('keyup', 'input[type="text"], textarea', function(){
  		let $this = $(this)
  		if($this.val().length >= 1) 
  			$(this).parent().addClass('typed')
  		else
  			$(this).parent().removeClass('typed')

  		if($this.val().length >= 3) {
  			$this.parent().find("label").addClass("error").text("Неверное сообщение")
  		} else {
  			if($this.attr("data-placeholder").length > 0)
  				$this.parent().find("label").removeClass("error").text($this.data("placeholder"))
  		}
  	})

	$('.slider-first .row, #section_5 .row').css('minHeight', $(window).height())
	$('#main').css("paddingBottom", $("#section_10").outerHeight())

	$(window).resize(function(){
		$('.slider-first .row, #section_5 .row').css('minHeight', $(window).height())
		$('#main').css("paddingBottom", $("#section_10").outerHeight())
	})


	$('.slider-first')
	//.on("init", function(){
	//	$(this).find('.slick-dots').wrap("<div class='pagination'></div>")
	//	$(this).find('.pagination').append('<svg viewBox="0 0 12 8"><path d="M6,8 C8.209139,8 10,6.209139 10,4 C10,1.790861 8.209139,0 6,0 C3.790861,0 2,1.790861 2,4 C2,6.209139 3.790861,8 6,8 Z" /></svg>')
	//}).on('afterChange', function(event, slick, currentSlide, nextSlide){
	//  	$(event.target).find('.pagination .slick-active button').change()
	//})
	.slick({
		fade: true,
		arrows: false,
		infinite: false,
		dots: false,
		autoplay: true,
		speed: 1000,
    	cssEase: 'ease-in-out'
	})


	$('#section_3 .slider')
	//.on("init", function(){
	//	$(this).find('.pull-left').css('left', $('.container').offset().left)
	//	$(this).find('.pull-right').css('left', $('.container').offset().left + $('.container').outerWidth())
	//})
	.slick({
		slidesToShow: 3,
		centerMode: true,
  		slidesToScroll: 2,
		arrows: true,
		infinite: true,
		dots: false,
		autoplay: true,
		speed: 1000,
    	cssEase: 'ease-in-out',
    	prevArrow:'<button type=\'button\' class=\'slick-prev pull-left\'><span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.5 9.25"><g><g><line class="cls-1" x1="0.75" y1="0.75" x2="5.75" y2="4.62"/><line x1="0.75" y1="8.5" x2="5.75" y2="4.62"/></g></g></svg></span></button>',
        nextArrow:'<button type=\'button\' class=\'slick-next pull-right\'><span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.5 9.25"><g><g><line class="cls-1" x1="0.75" y1="0.75" x2="5.75" y2="4.62"/><line x1="0.75" y1="8.5" x2="5.75" y2="4.62"/></g></g></svg></span></button>',
        responsive: [
  		  	{
  		  	  	breakpoint: 768,
  		  	  	settings: {
  		  	  	  	slidesToShow: 1,
  		  	  	  	slidesToScroll: 1
  		  	  	}
  		  	}
  		]
	
	})


	$('#section_4 .slider').on("init", function(){
		$(this).find('.slick-dots').wrap("<div class='center'></div>").wrap("<div class='pagination'></div>")
		$(this).find('.pagination').append('<svg viewBox="0 0 12 8"><path d="M6,8 C8.209139,8 10,6.209139 10,4 C10,1.790861 8.209139,0 6,0 C3.790861,0 2,1.790861 2,4 C2,6.209139 3.790861,8 6,8 Z" /></svg>')
		let cat = $(this).find('[data-slick-index="0"] .cat')
		let	title = $(this).find('[data-slick-index="0"] .title')
		let	txt = $(this).find('[data-slick-index="0"] .txt')

	  	gsap.fromTo(cat, {x: -40, opacity: 0}, {duration: .5, x: 0, opacity: 1})
	  	gsap.fromTo(title, {x: -40, opacity: 0}, {duration: .5, delay: .5,x: 0, opacity: 1})
	  	gsap.fromTo(txt, {x: -40, opacity: 0}, {duration: .5, delay: 1, x: 0, opacity: 1})
	}).on('beforeChange', function(event, slick, currentSlide, nextSlide){
		let cat = $(this).find('[data-slick-index="'+currentSlide+'"] .cat')
		let	title = $(this).find('[data-slick-index="'+currentSlide+'"] .title')
		let	txt = $(this).find('[data-slick-index="'+currentSlide+'"] .txt')

	  	gsap.to(cat, {opacity: 0})
	  	gsap.to(title, {opacity: 0})
	  	gsap.to(txt, {opacity: 0})
	}).on('afterChange', function(event, slick, currentSlide, nextSlide){
		let cat = $(this).find('[data-slick-index="'+currentSlide+'"] .cat')
		let	title = $(this).find('[data-slick-index="'+currentSlide+'"] .title')
		let	txt = $(this).find('[data-slick-index="'+currentSlide+'"] .txt')

	  	gsap.fromTo(cat, {x: -40, opacity: 0}, {duration: .5, x: 0, opacity: 1})
	  	gsap.fromTo(title, {x: -40, opacity: 0}, {duration: .5, delay: .5,x: 0, opacity: 1})
	  	gsap.fromTo(txt, {x: -40, opacity: 0}, {duration: .5, delay: 1, x: 0, opacity: 1})
	  	$(event.target).find('.pagination .slick-active button').change()
	}).slick({
		slidesToShow: 1.2,
		centerMode: true,
  		slidesToScroll: 1,
		arrows: true,
		infinite: false,
		dots: true,
		//autoplay: true,
		speed: 1000,
    	cssEase: 'ease-in-out',
    	prevArrow:'<button type=\'button\' class=\'slick-prev pull-left\'><span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.05 22.2"><g><g><polyline class="cls-1" points="11.11 1 1 11.11 1 11.08 11.11 21.2"/><line class="cls-1" x1="1" y1="11.12" x2="40.05" y2="11.12"/></g></g></svg></span></button>',
        nextArrow:'<button type=\'button\' class=\'slick-next pull-right\'><span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.05 22.2"><g><g><polyline points="29.93 21.2 40.05 11.08 40.05 11.11 29.93 1"/><line x1="40.04" y1="11.08" x2="1" y2="11.08"/></g></g></svg></span></button>',
        responsive: [
  		  	{
  		  	  	breakpoint: 991,
  		  	  	settings: {
  		  	  	  	slidesToShow: 1,
  		  	  	  	slidesToScroll: 1,
  		  	  	  	centerMode: false
  		  	  	}
  		  	}
  		]
	})

	$(document).scroll(function(){
		scrollCount()
	})

	$(document).on('click', "[data-tab]", function(){
		let $this = $(this)
		$("[data-tab], .slider").removeClass('active')
		$this.addClass('active')
		$($this.data('tab')).addClass('active')
	})
	
});